/** @jsxRuntime classic */
/** @jsx jsx */
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  Switch,
  SwitchProps,
} from '@mui/material';
import { ForwardRefRenderFunction, forwardRef } from 'react';
import { formControlLabelStyle } from './FormCheckbox.style';
import { jsx } from '@emotion/react';

export type FormCheckboxProps = { label: string } & (
  | ({ asSwitch?: false } & CheckboxProps)
  | ({ asSwitch: true } & SwitchProps)
);

const FormCheckbox: ForwardRefRenderFunction<
  HTMLButtonElement,
  FormCheckboxProps
> = ({ label, asSwitch = false, ...props }, ref) => (
  <FormControlLabel
    label={<span css={formControlLabelStyle}>{label}</span>}
    control={
      asSwitch ? (
        <Switch ref={ref} color="secondary" {...props} />
      ) : (
        <Checkbox ref={ref} color="secondary" {...props} />
      )
    }
  />
);

export default forwardRef(FormCheckbox);
