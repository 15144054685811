import { red as muiRed } from '@mui/material/colors';

export const acidGreen = '#67db00';
export const lightAcidGreen = '#67db001a';
export const acidOrange = '#ff5a39';
export const lightAcidOrange = '#ff5a391a';
export const blue500 = '#326cf0';
export const lightBlue500 = '#326cf01a';
export const white = '#ffffff';
export const gray200 = '#e7e7e7';
export const gray300 = '#dadada';
export const gray500 = '#767684';
export const gray700 = '#3b3b50';
export const red = muiRed[800];
export const grayBackground = '#f4f4f4';
