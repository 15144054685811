import { Button, ButtonProps } from '@mui/material';

import React from 'react';
import { useApolloClient } from '@apollo/client';
import { useAuth0 } from 'globalContexts/Auth0Context';
import { useTranslation } from 'react-i18next';

interface Props extends ButtonProps {}

export const LogoutButton: React.FunctionComponent<Props> = (props) => {
  const client = useApolloClient();
  const { logout } = useAuth0();
  const { t } = useTranslation();

  const handleLogout = async () => {
    logout();
    await client.resetStore();
  };

  return (
    <Button
      variant="contained"
      color="error"
      size="large"
      {...props}
      onClick={handleLogout}
      sx={{ m: 1 }}
    >
      {t('common.buttons.logout')}
    </Button>
  );
};
