/** @jsxRuntime classic */
/** @jsx jsx */
import {
  FormGroup as MuiFormGroup,
  FormGroupProps as MuiFormGroupProps,
} from '@mui/material';
import { FC } from 'react';
import { formGroupRootStyle } from './FormGroup.style';
import { jsx } from '@emotion/react';

export type FormGroupProps = MuiFormGroupProps;

const FormGroup: FC<FormGroupProps> = ({ children }) => {
  return <MuiFormGroup css={formGroupRootStyle}>{children}</MuiFormGroup>;
};

export default FormGroup;
