import { MouseEvent, useState } from 'react';
import { useAuth0 } from 'globalContexts/Auth0Context';

export function useHeader() {
  const [profileMenuAnchor, setProfileMenuAnchor] = useState<
    HTMLElement | undefined
  >(undefined);

  function openProfileMenu(e: MouseEvent<HTMLElement>) {
    setProfileMenuAnchor(e.currentTarget);
  }

  function closeProfileMenu() {
    setProfileMenuAnchor(undefined);
  }

  const { logout } = useAuth0();

  function disconnectAction() {
    closeProfileMenu();
    logout();
  }

  return {
    openProfileMenu,
    closeProfileMenu,
    isMenuOpen: !!profileMenuAnchor,
    profileMenuAnchor,
    disconnectAction,
  };
}
