import {
  ConditioningOperator,
  DocumentOptions,
  DocumentOrderType,
} from 'models/graphql/globalTypes';
import { TFunction } from 'i18next';

export function getDocumentOrderTypeName(
  type: DocumentOrderType,
  t: TFunction
): string {
  switch (type) {
    case DocumentOrderType.Commitment:
      return t('pages.order_workflow.form.field_type_value_commitment');
    case DocumentOrderType.FreeOrder:
      return t('pages.order_workflow.form.field_type_value_order');
    default:
      return type;
  }
}

export function getDocumentOptionName(
  option: DocumentOptions,
  t: TFunction
): string {
  switch (option) {
    case DocumentOptions.customizable:
      return t('pages.order_workflow.form.field_option_value_customizable');
    case DocumentOptions.notCustomizable:
      return t('pages.order_workflow.form.field_option_value_noncustomizable');
    default:
      return option;
  }
}

export function getConditioningOperatorName(
  option: ConditioningOperator,
  t: TFunction
): string {
  switch (option) {
    case ConditioningOperator.upper:
      return t('pages.order_workflow.form.field_criterion_value_greater');
    case ConditioningOperator.lower:
      return t('pages.order_workflow.form.field_criterion_value_lesser');
    default:
      return option;
  }
}

export function arrayAt<T>(
  array: T[] | null | undefined,
  index: number
): T | undefined {
  if (array && array.length > index) {
    return array[index];
  } else {
    return undefined;
  }
}
