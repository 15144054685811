import React from 'react';
import { SplashScreen } from 'components/SplashScreen/SplashScreen';
import { useTranslation } from 'react-i18next';

export const Unauthorized401: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <SplashScreen
      body={t('pages.error_401.body')}
      title={t('pages.error_401.title')}
      logoutButton
      backToBigOneButton
    />
  );
};
