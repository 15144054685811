import {
  BigShopConfigCategory,
  ConditioningOperator,
  DocumentOptions,
  DocumentOrderType,
  PriceConditioningInput,
  ValidatorInput,
} from 'models/graphql/globalTypes';
import {
  CREATE_ORDER_WORKFLOW,
  GET_ORDER_WORKFLOW_CONFIG,
  UPDATE_ORDER_WORKFLOW,
} from '../../../graphql/orderWorkflow';
import {
  CreateOrderWorkflow,
  CreateOrderWorkflowVariables,
} from 'models/graphql/CreateOrderWorkflow';
import { DefaultValues, useForm } from 'react-hook-form';
import {
  UpdateOrderWorkflow,
  UpdateOrderWorkflowVariables,
} from 'models/graphql/UpdateOrderWorkflow';
import { arrayAt, getConditioningOperatorName } from '../utils';
import { getAutocompleteValue, getSupportCategoryName } from 'utils/mappers';
import { useEffect, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FormSelectOption } from 'components/FormSelect/FormSelect';
import { GetOrderWorkflowConfig } from 'models/graphql/GetOrderWorkflowConfig';
import { GetOrderWorkflowList_orderWorkflows } from 'models/graphql/GetOrderWorkflowList';
import { useTranslation } from 'react-i18next';

interface FormValues {
  category: BigShopConfigCategory;
  type: DocumentOrderType[];
  option: DocumentOptions[];
  add_condition: boolean;
  criterion: ConditioningOperator;
  value: number;
  recipient: string;
  relay: string;
  active: boolean;
}

export interface UseOrderWorkflowFormParams {
  onClose: (success: boolean) => void;
  disableCategories: BigShopConfigCategory[];
  edit?: GetOrderWorkflowList_orderWorkflows;
}

export function useOrderWorkflowForm({
  onClose,
  disableCategories,
  edit,
}: UseOrderWorkflowFormParams) {
  const { t } = useTranslation();
  const { data: flowConfigData } = useQuery<GetOrderWorkflowConfig>(
    GET_ORDER_WORKFLOW_CONFIG
  );
  const [createLaunch] = useMutation<
    CreateOrderWorkflow,
    CreateOrderWorkflowVariables
  >(CREATE_ORDER_WORKFLOW);
  const [updateLaunch] = useMutation<
    UpdateOrderWorkflow,
    UpdateOrderWorkflowVariables
  >(UPDATE_ORDER_WORKFLOW);

  const categoryOptions = useMemo<FormSelectOption[]>(
    () =>
      flowConfigData?.supports
        .map((v) => v.onePacConfig && v.onePacConfig.category)
        .filter((v): v is BigShopConfigCategory => !!v) // ignore empty values
        .filter((v, i, a) => i === a.indexOf(v)) // extract unique values
        .map((v) => ({
          value: v,
          label: getSupportCategoryName(v, t),
          disabled: disableCategories.includes(v),
        })) ?? [],
    [flowConfigData, t, disableCategories]
  );

  const recipientOptions = useMemo(
    () =>
      flowConfigData?.getClientPublicUserProfiles.map((v) => ({
        label: `${v.givenName} ${v.familyName} <${v.email}>`,
        value: v.id,
      })) ?? [],
    [flowConfigData]
  );

  const criterionOptions = useMemo<FormSelectOption[]>(
    () =>
      Object.values(ConditioningOperator).map((v) => ({
        value: v,
        label: getConditioningOperatorName(v, t),
      })),
    [t]
  );

  const defaultValues: DefaultValues<FormValues> = useMemo(() => {
    const priceConditioning = arrayAt(edit?.priceConditioning, 0);
    const validator = arrayAt(edit?.validators, 0);
    const recipient = validator?.mainUser;
    const relay = arrayAt(validator?.fallbackUsers, 0);

    return {
      category:
        edit?.supportCategory ??
        categoryOptions.find((v) => !v.disabled)?.value ?? // first non disabled category
        ('' as any),
      type: edit?.documentOrderTypes ?? [],
      option: edit?.documentOptions ?? [],
      add_condition: !!priceConditioning ?? false,
      criterion: priceConditioning?.operator ?? ConditioningOperator.upper,
      value: priceConditioning?.value ?? ('' as any),
      recipient:
        (recipient
          ? recipientOptions.find((v) => v.value === recipient.id)?.label
          : undefined) ?? '',
      relay:
        (relay
          ? recipientOptions.find((v) => v.value === relay.id)?.label
          : undefined) ?? '',
      active: edit?.active ?? true,
    };
  }, [categoryOptions, recipientOptions, edit]);

  const form = useForm<FormValues>({ defaultValues });
  useEffect(() => form.reset(defaultValues), [form, defaultValues]);

  async function onSubmit(data: FormValues) {
    const priceConditioning: PriceConditioningInput[] = [];
    if (data.add_condition) {
      priceConditioning.push({
        operator: data.criterion,
        value: data.value,
      });
    }

    const validator1: ValidatorInput = {
      mainUser: getAutocompleteValue(recipientOptions, data.recipient),
      fallbackUsers: [],
      optional: false,
    };
    if (data.relay) {
      validator1.fallbackUsers.push(
        getAutocompleteValue(recipientOptions, data.relay)
      );
    }

    const input = {
      documentOrderTypes: data.type,
      documentOptions: data.option,
      priceConditioning: priceConditioning,
      validators: [validator1],
      active: data.active,
    };

    if (edit) {
      await updateLaunch({ variables: { id: edit.id, data: input } });
    } else {
      await createLaunch({
        variables: { data: { ...input, supportCategory: data.category } },
      });
    }

    close(true);
  }

  function close(success: boolean) {
    form.reset(defaultValues);
    onClose(success);
  }

  const recipient = form.watch('recipient');
  const relay = form.watch('relay');
  const relayOptions = recipientOptions.filter((it) => it.label !== recipient);
  useEffect(() => {
    if (!recipient || recipient === relay) {
      form.setValue('relay', '');
    }
  }, [form, recipient, relay]);

  return {
    handleClose: () => close(false),
    handleSubmit: form.handleSubmit(onSubmit),
    form,
    categoryOptions,
    recipientOptions,
    conditionAdded: form.watch('add_condition'),
    defaultValues,
    criterionOptions,
    hasRecipient: !!recipient,
    relayOptions,
  };
}
