import { gql } from '@apollo/client';

export const GET_ORDER_WORKFLOW_CONFIG = gql`
  query GetOrderWorkflowConfig {
    supports {
      id
      onePacConfig {
        category
      }
    }
    getClientPublicUserProfiles(filters: {}) {
      id
      givenName
      familyName
      email
    }
  }
`;

export const CREATE_ORDER_WORKFLOW = gql`
  mutation CreateOrderWorkflow($data: CreateOrderWorkflowInput!) {
    createOrderWorkflow(createOrderWorkflowInput: $data) {
      id
    }
  }
`;

export const GET_ORDER_WORKFLOW_LIST = gql`
  query GetOrderWorkflowList {
    orderWorkflows {
      id
      supportCategory
      documentOrderTypes
      documentOptions
      priceConditioning {
        operator
        value
      }
      validators {
        mainUser {
          id
          givenName
          familyName
        }
        fallbackUsers {
          id
          givenName
          familyName
        }
      }
      active
    }
    supports {
      id
      onePacConfig {
        category
      }
    }
  }
`;

export const UPDATE_ORDER_WORKFLOW = gql`
  mutation UpdateOrderWorkflow($id: ID!, $data: UpdateOrderWorkflowInput!) {
    updateOrderWorkflow(id: $id, updateOrderWorkflowInput: $data) {
      id
    }
  }
`;

export const DELETE_ORDER_WORKFLOW = gql`
  mutation DeleteOrderWorkflow($id: ID!) {
    deleteOrderWorkflow(id: $id) {
      acknowledged
    }
  }
`;
