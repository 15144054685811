/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BigShopConfigCategory {
  AdvertisingItems = 'AdvertisingItems',
  AdvertisingPrints = 'AdvertisingPrints',
  Digital = 'Digital',
  SignagePos = 'SignagePos',
  StationeryShop = 'StationeryShop',
}

export enum ConditioningOperator {
  lower = 'lower',
  upper = 'upper',
}

export enum DocumentOptions {
  customizable = 'customizable',
  notCustomizable = 'notCustomizable',
}

export enum DocumentOrderType {
  Commitment = 'Commitment',
  FreeOrder = 'FreeOrder',
  National = 'National',
  PrintableInStore = 'PrintableInStore',
}

export enum UserType {
  Global = 'Global',
  Local = 'Local',
}

export interface CreateOrderWorkflowInput {
  supportCategory: BigShopConfigCategory;
  documentOrderTypes: DocumentOrderType[];
  documentOptions: DocumentOptions[];
  priceConditioning?: PriceConditioningInput[] | null;
  validators: ValidatorInput[];
  active: boolean;
}

export interface PriceConditioningInput {
  operator: ConditioningOperator;
  value: number;
}

export interface UpdateOrderWorkflowInput {
  supportCategory?: BigShopConfigCategory | null;
  documentOrderTypes?: DocumentOrderType[] | null;
  documentOptions?: DocumentOptions[] | null;
  priceConditioning?: PriceConditioningInput[] | null;
  validators?: ValidatorInput[] | null;
  active?: boolean | null;
}

export interface ValidatorInput {
  mainUser: string;
  fallbackUsers: string[];
  optional: boolean;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
