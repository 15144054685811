import { gql } from '@apollo/client';

export const CURRENT_USER = gql`
  query CurrentUser {
    currentUser: getCurrentUser {
      id
      email
      givenName
      familyName
      type
    }
  }
`;
