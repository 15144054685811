/** @jsxRuntime classic */
/** @jsx jsx */
import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material';
import { tooltipPopperStyle, tooltipRootStyle } from './Tooltip.style';
import { ClassNames } from '@emotion/react';
import { FC } from 'react';
import { jsx } from '@emotion/react';

export type TooltipProps = MuiTooltipProps;

const Tooltip: FC<TooltipProps> = ({ children, ...props }) => {
  return (
    <ClassNames>
      {({ css }) => (
        <MuiTooltip
          {...props}
          css={tooltipRootStyle}
          classes={{
            popper: css`
              ${tooltipPopperStyle}
            `,
          }}
        >
          {children}
        </MuiTooltip>
      )}
    </ClassNames>
  );
};

export default Tooltip;
