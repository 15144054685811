import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';

export type FormAutocompleteProps<T, F extends FieldValues> = Omit<
  AutocompleteProps<T, undefined, undefined, undefined>,
  'renderInput'
> &
  Pick<TextFieldProps, 'error'> &
  Pick<ControllerProps<F>, 'control' | 'name' | 'rules'>;

const FormAutocomplete = <T, F extends FieldValues>({
  control,
  name,
  rules,
  error,
  placeholder,
  ...props
}: FormAutocompleteProps<T, F>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <Autocomplete<T>
          {...props}
          {...field}
          onChange={(a, v) => field.onChange(v)}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              error={error}
              placeholder={placeholder}
              hiddenLabel
              size="small"
            />
          )}
        />
      )}
    />
  );
};

export default FormAutocomplete;
