import {
  CurrentUser,
  CurrentUser_currentUser,
} from 'models/graphql/CurrentUser';
import { createContext, useContext } from 'react';

import { BadRequest400 } from 'components/StaticPages/BadRequest400';
import { CURRENT_USER } from 'graphql/user';
import { Loader } from 'components/Loader/Loader';
import { Unauthorized401 } from 'components/StaticPages/Unauthorized401';
import { UserType } from 'models/graphql/globalTypes';
import { useQuery } from '@apollo/client';

export const UserContext = createContext<CurrentUser_currentUser | undefined>(
  undefined
);

export const useActiveUser = () => {
  const context = useContext(UserContext);
  if (!context)
    throw new Error(
      'useActiveUser must be used inside a Provider with a value.'
    );
  return context;
};

export const UserProvider: React.FunctionComponent = ({ children }) => {
  const { data, loading, error } = useQuery<CurrentUser>(CURRENT_USER);
  if (error) return <BadRequest400 />;
  if (loading) return <Loader height="100vh" />;
  if (data?.currentUser.type !== UserType.Global) return <Unauthorized401 />;
  return (
    <UserContext.Provider value={data?.currentUser}>
      {children}
    </UserContext.Provider>
  );
};
