import {} from 'const/colors';
import { css } from '@emotion/react';

export const menuRootStyle = css`
  .MuiMenu-list {
    background: #ffffff;
    border: 2px solid #f4f4f4;
    border-radius: 4px;
    min-width: 165px;
  }
`;
