/** @jsxRuntime classic */
/** @jsx jsx */

import { Loader } from 'components/Loader/Loader';
import OrderWorkflowDeleteDialog from './OrderWorkflowDeleteDialog/OrderWorkflowDeleteDialog';
import OrderWorkflowForm from './OrderWorkflowForm/OrderWorkflowForm';
import OrderWorkflowList from './OrderWorkflowList/OrderWorkflowList';
import OrderWorkflowListEmpty from './OrderWorkflowListEmpty/OrderWorkflowListEmpty';
import Snackbar from 'components/Snackbar/Snackbar';
import { jsx } from '@emotion/react';
import { orderWorkflowPageStyle } from './OrderWorkflowPage.style';
import { useActiveUser } from 'globalContexts/UserContext';
import { useOrderWorkflowDeleteDialog } from './OrderWorkflowDeleteDialog/OrderWorkflowDeleteDialog.hooks';
import { useOrderWorkflowPage } from './OrderWorkflowPage.hook';
import { useTranslation } from 'react-i18next';

const OrderWorkflowPage = () => {
  const { t } = useTranslation();
  const activeUser = useActiveUser();
  const {
    formOpen,
    formEdit,
    successNotifOpen,
    handleOpenForm,
    handleCloseForm,
    listData,
    listError,
    disabledSupportCategories,
    handleCloseSuccessNotif,
    canAddMore,
  } = useOrderWorkflowPage();

  const {
    isDialogOpen,
    handleOpenDialog,
    handleCloseDilalog,
    onSubmit,
    setItemToDelete,
  } = useOrderWorkflowDeleteDialog();

  return (
    <div css={orderWorkflowPageStyle}>
      <h4>
        {t('pages.notification_list.body', {
          fullName: `${activeUser?.givenName},`,
        })}
      </h4>
      <h1>{t('pages.order_workflow.flow')} </h1>
      {listData ? (
        listData.orderWorkflows.length > 0 ? (
          <OrderWorkflowList
            items={listData.orderWorkflows}
            onOpenForm={handleOpenForm}
            canAddMore={canAddMore}
            handleOpenDialog={handleOpenDialog}
            setItemToDelete={setItemToDelete}
          />
        ) : (
          <OrderWorkflowListEmpty onOpenForm={handleOpenForm} />
        )
      ) : (
        <Loader height="256px" error={listError} />
      )}
      <OrderWorkflowForm
        open={formOpen}
        onClose={handleCloseForm}
        disableCategories={disabledSupportCategories}
        edit={formEdit}
      />
      <OrderWorkflowDeleteDialog
        open={isDialogOpen}
        onClose={handleCloseDilalog}
        onSubmit={(event) => onSubmit(event)}
      />
      <Snackbar
        open={successNotifOpen}
        onClose={handleCloseSuccessNotif}
        severity="success"
        message={
          !!formEdit
            ? t('pages.order_workflow.snackbar_success_edit')
            : t('pages.order_workflow.snackbar_success_create')
        }
      />
    </div>
  );
};

export default OrderWorkflowPage;
