import { gray500, red } from 'const/colors';
import { css } from '@emotion/react';

export const formLabelRootStyle = css`
  display: block;
  font-family: 'ScandiaWebRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const formLabelRequiredStyle = css`
  font-family: 'ScandiaWebMedium';
  font-weight: 400;
`;

export const formLabelHelpTextIconStyle = css`
  color: ${gray500};
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-left: 8px;
`;

export const formLabelErrorStyle = css`
  color: ${red};
`;
