/** @jsxRuntime classic */
/** @jsx jsx */
import { AppBar, Button, Divider, ListItemIcon, Toolbar } from '@mui/material';
import { IconButton, ListItemText } from '@mui/material';
import { Link, matchPath, useLocation } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Box } from '@mui/system';
import Menu from 'components/Menu/Menu';
import MenuItem from 'components/MenuItem/MenuItem';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import React from 'react';
import { headerStyle } from './Header.style';
import { jsx } from '@emotion/react';
import { navContent } from 'const/nav';
import { routeHome } from 'const/appRoutes';
import { useHeader } from './Header.hooks';
import { useTranslation } from 'react-i18next';

type Props = {};

export const Header: React.FunctionComponent<Props> = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const {
    openProfileMenu,
    closeProfileMenu,
    isMenuOpen,
    profileMenuAnchor,
    disconnectAction,
  } = useHeader();

  return (
    <AppBar position="static" color="default" elevation={0} css={headerStyle}>
      <Toolbar className="toolbar">
        <Box className="logo-box">
          <Link to={routeHome} className="logo-link" data-testid="home">
            <img src={'/images/logobigone.png'} alt="logo" />
            <Divider orientation="vertical" flexItem />
            <img src={'/images/logoonemanager.png'} alt="logo" />
          </Link>
        </Box>
        <nav>
          {navContent.map(({ label, route }) => (
            <Button
              className="button-nav"
              key={label}
              component={Link}
              to={route}
              sx={{ my: 1, mx: 1.5 }}
              variant="text"
              color={
                matchPath(`${route}/*`, location.pathname)
                  ? 'secondary'
                  : 'primary'
              }
            >
              <p>{t(label)}</p>
            </Button>
          ))}
        </nav>
        <Box className="logout-box">
          <Divider orientation="vertical" flexItem />
          <IconButton onClick={openProfileMenu}>
            <AccountCircleOutlinedIcon fontSize="medium" />
          </IconButton>
        </Box>
      </Toolbar>
      <Menu
        open={isMenuOpen}
        anchorEl={profileMenuAnchor}
        onClose={closeProfileMenu}
      >
        <MenuItem onClick={disconnectAction}>
          <ListItemIcon>
            <PowerSettingsNewIcon />
          </ListItemIcon>
          <ListItemText>{t('common.nav.disconnect')}</ListItemText>
        </MenuItem>
      </Menu>
    </AppBar>
  );
};
