import {
  GetOrderWorkflowList,
  GetOrderWorkflowList_orderWorkflows,
} from 'models/graphql/GetOrderWorkflowList';
import { useMemo, useState } from 'react';
import { BigShopConfigCategory } from 'models/graphql/globalTypes';
import { GET_ORDER_WORKFLOW_LIST } from '../../graphql/orderWorkflow';
import { useQuery } from '@apollo/client';

export function useOrderWorkflowPage() {
  const [formOpen, setFormOpen] = useState(false);
  const [formEdit, setFormEdit] = useState<
    GetOrderWorkflowList_orderWorkflows | undefined
  >(undefined);
  const [successNotifOpen, setSuccessNotifOpen] = useState(false);

  function handleOpenForm(id?: string) {
    setFormEdit(
      id ? listData?.orderWorkflows.find((v) => v.id === id) : undefined
    );
    setFormOpen(true);
  }

  function handleCloseForm(success: boolean) {
    setFormOpen(false);
    if (success) {
      setSuccessNotifOpen(true);
      listRefetch();
    }
  }

  const {
    data: listData,
    error: listError,
    refetch: listRefetch,
  } = useQuery<GetOrderWorkflowList>(GET_ORDER_WORKFLOW_LIST);

  const disabledSupportCategories = useMemo(
    () =>
      listData?.orderWorkflows
        .map((v) => v.supportCategory)
        .filter((v, i, a) => i === a.indexOf(v)) ?? [], // extract unique values
    [listData]
  );

  function handleCloseSuccessNotif() {
    setSuccessNotifOpen(false);
  }

  const categories = useMemo<BigShopConfigCategory[]>(
    () =>
      listData?.supports
        .map((v) => v.onePacConfig && v.onePacConfig.category)
        .filter((v): v is BigShopConfigCategory => !!v) // ignore empty values
        .filter((v, i, a) => i === a.indexOf(v)) ?? [], // extract unique values
    [listData]
  );

  const canAddMore = disabledSupportCategories.length < categories.length;

  return {
    formOpen,
    formEdit,
    successNotifOpen,
    handleOpenForm,
    handleCloseForm,
    listData,
    listError,
    disabledSupportCategories,
    handleCloseSuccessNotif,
    canAddMore,
    listRefetch,
  };
}
