import { ForwardRefRenderFunction, forwardRef } from 'react';
import { MenuItem, Select, SelectProps } from '@mui/material';
import Tooltip from 'components/Tooltip/Tooltip';

export interface FormSelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}

export interface FormSelectProps extends SelectProps {
  options: FormSelectOption[];
  disabledHelpText?: string;
}

const FormSelect: ForwardRefRenderFunction<
  HTMLInputElement,
  FormSelectProps
> = ({ options, disabledHelpText, ...props }, ref) => (
  <Select {...props} ref={ref} fullWidth size="small">
    {options.map((v) =>
      v.disabled && disabledHelpText ? (
        <Tooltip key={v.value} title={disabledHelpText}>
          <MenuItem value={v.value} disabled>
            {v.label}
          </MenuItem>
        </Tooltip>
      ) : (
        <MenuItem key={v.value} value={v.value} disabled={v.disabled}>
          {v.label}
        </MenuItem>
      )
    )}
  </Select>
);

export default forwardRef(FormSelect);
