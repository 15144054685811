import { css } from '@emotion/react';
import { white } from 'const/colors';

export const avatarRootStyle = css`
  width: 34px;
  height: 34px;
  border: 2px solid ${white};

  font-family: 'ScandiaWebMedium';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${white};
`;
