/** @jsxRuntime classic */
/** @jsx jsx */
import {
  orderWorkflowPageInfoImageStyle,
  orderWorkflowPageInfoStyle,
  orderWorkflowPageInfoTextStyle,
} from '../OrderWorkflowPage.style';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from 'components/Button/Button';
import { FC } from 'react';
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';

export interface OrderWorkflowListEmptyProps {
  onOpenForm: () => void;
}

const OrderWorkflowListEmpty: FC<OrderWorkflowListEmptyProps> = ({
  onOpenForm,
}) => {
  const { t } = useTranslation();

  return (
    <div css={orderWorkflowPageInfoStyle}>
      <img
        src="/images/validationPage.png"
        alt=""
        css={orderWorkflowPageInfoImageStyle}
      />
      <h5 css={orderWorkflowPageInfoTextStyle}>
        {t('pages.order_workflow.no_flow')}
      </h5>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<AddCircleIcon />}
        onClick={onOpenForm}
      >
        {t('pages.order_workflow.add_flow')}
      </Button>
    </div>
  );
};

export default OrderWorkflowListEmpty;
