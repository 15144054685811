import { css } from '@emotion/react';

export const orderWorkflowPageStyle = css`
  h1 {
    font-family: ScandiaWebMedium;
    margin-top: 16px;
  }
`;

export const orderWorkflowPageInfoImageStyle = css`
  width: 240px;
  height: 240px;
  margin-top: 67px;
`;

export const orderWorkflowPageInfoTextStyle = css`
  font-family: ScandiaWebMedium;
  margin-top: 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`;

export const orderWorkflowPageInfoStyle = css`
  text-align: center;
`;
