import {
  acidGreen,
  acidOrange,
  lightAcidGreen,
  lightAcidOrange,
} from 'const/colors';
import { css } from '@emotion/react';

export const activationBadgeRootStyle = css`
  font-family: 'ScandiaWebMedium';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  padding: 5px 10px;
  border-radius: 26px;
  height: 24px;
`;

export const activationBadgeActiveStyle = css`
  background: ${lightAcidGreen};
  color: ${acidGreen};
`;

export const activationBadgeInactiveStyle = css`
  background: ${lightAcidOrange};
  color: ${acidOrange};
`;
