import { ForwardRefRenderFunction, forwardRef } from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';

export type FormTextFieldProps = TextFieldProps & {
  endAdornment?: string;
};

const FormTextField: ForwardRefRenderFunction<
  HTMLInputElement,
  FormTextFieldProps
> = ({ endAdornment, ...props }, ref) => {
  const inputProps: NonNullable<FormTextFieldProps['InputProps']> = {
    ...props.InputProps,
  };
  if (endAdornment) {
    inputProps.endAdornment = (
      <InputAdornment position="end">{endAdornment}</InputAdornment>
    );
  }

  return (
    <TextField
      {...props}
      ref={ref}
      hiddenLabel
      fullWidth
      size="small"
      InputProps={inputProps}
    />
  );
};

export default forwardRef(FormTextField);
