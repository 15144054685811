/** @jsxRuntime classic */
/** @jsx jsx */
import { FC, ReactNode } from 'react';
import {
  formLabelErrorStyle,
  formLabelHelpTextIconStyle,
  formLabelRequiredStyle,
  formLabelRootStyle,
} from './FormLabel.style';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from 'components/Tooltip/Tooltip';
import { jsx } from '@emotion/react';

export interface FormLabelProps {
  children: ReactNode;
  htmlFor?: string;
  helpText?: string;
  required?: boolean;
  error?: boolean;
}

const FormLabel: FC<FormLabelProps> = ({
  children,
  helpText,
  htmlFor,
  required = true,
  error = false,
}) => {
  return (
    <label
      css={[
        formLabelRootStyle,
        required && formLabelRequiredStyle,
        error && formLabelErrorStyle,
      ]}
      htmlFor={htmlFor}
    >
      {children}
      {required && ' *'}
      {helpText && (
        <Tooltip title={helpText}>
          <HelpOutlineIcon css={formLabelHelpTextIconStyle} />
        </Tooltip>
      )}
    </label>
  );
};

export default FormLabel;
