/** @jsxRuntime classic */
/** @jsx jsx */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import {
  UseOrderWorkflowListParams,
  useOrderWorkflowList,
} from './OrderWorkflowList.hook';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from 'components/Button/Button';
import { FC } from 'react';
import { GetOrderWorkflowList_orderWorkflows } from 'models/graphql/GetOrderWorkflowList';
import OrderWorkflowListItem from '../OrderWorkflowListItem/OrderWorkflowListItem';
import TableHead from 'components/TableHead/TableHead';
import { jsx } from '@emotion/react';
import { orderWorkflowListActionBarStyle } from './OrderWorkflowList.style';
import { useTranslation } from 'react-i18next';

export interface OrderWorkflowListProps extends UseOrderWorkflowListParams {
  items: GetOrderWorkflowList_orderWorkflows[];
  canAddMore: boolean;
  handleOpenDialog: () => void;
  setItemToDelete: React.Dispatch<React.SetStateAction<string | null>>;
}

const OrderWorkflowList: FC<OrderWorkflowListProps> = ({
  items,
  canAddMore,
  handleOpenDialog,
  setItemToDelete,
  ...params
}) => {
  const { t } = useTranslation();
  const { handleNewFlow, handleEditFlow } = useOrderWorkflowList(params);

  return (
    <div>
      <div css={orderWorkflowListActionBarStyle}>
        {canAddMore && (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddCircleIcon />}
            onClick={handleNewFlow}
          >
            {t('common.buttons.add')}
          </Button>
        )}
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {t('pages.order_workflow.list.head_category')}
              </TableCell>
              <TableCell>
                {t('pages.order_workflow.list.head_option')}
              </TableCell>
              <TableCell>{t('pages.order_workflow.list.head_type')}</TableCell>
              <TableCell>
                {t('pages.order_workflow.list.head_condition')}
              </TableCell>
              <TableCell>
                {t('pages.order_workflow.list.head_criterion')}
              </TableCell>
              <TableCell>
                {t('pages.order_workflow.list.head_recipients')}
              </TableCell>
              <TableCell>
                {t('pages.order_workflow.list.head_status')}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((it) => (
              <OrderWorkflowListItem
                key={it.id}
                it={it}
                onOpenEditMenu={() => handleEditFlow(it.id)}
                handleOpenDialog={handleOpenDialog}
                setItemToDelete={setItemToDelete}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OrderWorkflowList;
