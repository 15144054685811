import LanguageDetector from 'i18next-browser-languagedetector';
import de from './lang/de.json';
import en from './lang/en.json';
import fr from './lang/fr.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import vls from './lang/vls.json';

export enum Language {
  DE = 'de', // temporary
  EN = 'en',
  FR = 'fr',
  VLS = 'vls',
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
      de: {
        translation: de,
      },
      vls: {
        translation: vls,
      },
    },
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
