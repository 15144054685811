import {} from 'const/colors';
import { css } from '@emotion/react';

export const menuItemRootStyle = css`
  height: 42px;

  font-family: ScandiaWebMedium;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;

  color: #326cf0;

  .MuiListItemIcon-root {
    color: inherit;
  }

  .MuiListItemText-root .MuiTypography-root {
    font: inherit;
  }
`;
