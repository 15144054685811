/** @jsxRuntime classic */
/** @jsx jsx */
import {
  Alert,
  AlertProps,
  Snackbar as MuiSnackbar,
  SnackbarProps as MuiSnackbarProps,
} from '@mui/material';
import { snackbarAlertStyle, snackbarRootStyle } from './Snackbar.style';
import { FC } from 'react';
import { jsx } from '@emotion/react';

export interface SnackbarProps extends MuiSnackbarProps {
  onClose: () => void;
  severity: AlertProps['severity'];
}

const Snackbar: FC<SnackbarProps> = ({
  autoHideDuration = 6000,
  onClose,
  severity = 'info',
  message,
  children,
  ...props
}) => (
  <MuiSnackbar
    {...props}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    onClose={onClose}
    autoHideDuration={autoHideDuration}
    css={snackbarRootStyle}
  >
    <Alert onClose={onClose} severity={severity} css={snackbarAlertStyle}>
      {message ?? children}
    </Alert>
  </MuiSnackbar>
);

export default Snackbar;
