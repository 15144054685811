import { Route, Routes } from 'react-router-dom';
import {
  routeBadRequest400,
  routeHome,
  routeNotFound404,
  routeOrderWorkflow,
} from 'const/appRoutes';

import { BadRequest400 } from 'components/StaticPages/BadRequest400';
import { GlobalLayout } from 'components/Layout/Layout';
import { Home } from 'containers/Home/Home';
import { Notfound404 } from 'components/StaticPages/Notfound404';
import OrderWorkflowPage from 'containers/OrderWorkflow/OrderWorkflowPage';
import React from 'react';

type Props = {};

export const AppRoutes: React.FunctionComponent<Props> = () => {
  return (
    <Routes>
      <Route path={routeHome} element={<GlobalLayout />}>
        <Route index element={<Home />} />
        <Route path={routeOrderWorkflow}>
          <Route index element={<OrderWorkflowPage />} />
        </Route>
      </Route>
      <Route path={routeBadRequest400} element={<BadRequest400 />} />
      <Route path={routeNotFound404} element={<Notfound404 />} />
    </Routes>
  );
};
