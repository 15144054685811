import { Navigate } from 'react-router-dom';
import React from 'react';
import { Typography } from '@mui/material';
import { routeOrderWorkflow } from 'const/appRoutes';
import { useTranslation } from 'react-i18next';

type Props = {};

export const Home: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Typography variant="h4" component="h1" mb={1}>
        {t('pages.home.title')}
      </Typography>
      <Navigate to={routeOrderWorkflow} replace={true} />
    </div>
  );
};
