import { Global, css } from '@emotion/react';
import { FC } from 'react';

interface FontProps {
  name: string;
  path: string;
}

const Font: FC<FontProps> = ({ name, path }) => (
  <Global
    styles={css`
      @font-face {
        font-family: '${name}';
        src: url('${path}/${name}.eot');
        src: url('${path}/${name}.eot?#iefix') format('embedded-opentype'),
          url('${path}/${name}.woff2') format('woff2'),
          url('${path}/${name}.woff') format('woff');
      }
    `}
  />
);

export const Fonts: FC = () => (
  <>
    <Font name="ScandiaWebBold" path="./../fonts/Scandia/ScandiaWebBold" />
    <Font
      name="ScandiaWebBoldItalic"
      path="/fonts/Scandia/ScandiaWebBoldItalic"
    />
    <Font name="ScandiaWebLight" path="/fonts/Scandia/ScandiaWebLight" />
    <Font
      name="ScandiaWebLightItalic"
      path="/fonts/Scandia/ScandiaWebLightItalic"
    />
    <Font name="ScandiaWebMedium" path="/fonts/Scandia/ScandiaWebMedium" />
    <Font
      name="ScandiaWebMediumItalic"
      path="/fonts/Scandia/ScandiaWebMediumItalic"
    />
    <Font name="ScandiaWebRegular" path="/fonts/Scandia/ScandiaWebRegular" />
    <Font
      name="ScandiaWebRegularItalic"
      path="/fonts/Scandia/ScandiaWebRegularItalic"
    />
    <Font name="ScandiaWebStencil" path="/fonts/Scandia/ScandiaWebStencil" />
    <Font
      name="MontserratWebRegular"
      path="/fonts/Montserrat/MontserratWebRegular"
    />
    <Font name="MontserratWebBold" path="/fonts/Montserrat/MontserratWebBold" />
  </>
);
