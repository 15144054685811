import { Box, Button, Container, Typography } from '@mui/material';
import { blue500, white } from 'const/colors';

import { Link } from 'react-router-dom';
import { LogoutButton } from 'components/Logout/LogoutButton';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { routeHome } from 'const/appRoutes';
import { useTranslation } from 'react-i18next';

// import { useApolloClient } from '@apollo/client';

interface SplashScreenProps {
  title?: string;
  body?: string;
  backToBigOneButton?: boolean;
  logoutButton?: boolean;
  backToHomeButton?: boolean;
}

export const SplashScreen = ({
  title,
  body,
  backToBigOneButton,
  backToHomeButton,
  logoutButton,
}: SplashScreenProps): JSX.Element => {
  const classes = makeStyles(() => ({
    background: {
      height: '100vh',
      backgroundColor: blue500,
    },
  }))();

  const { t } = useTranslation();

  const logoUrl = `/images/logo.png`;

  return (
    <Box
      className={classes.background}
      display="flex"
      flexDirection="column"
      textAlign="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        flexGrow={1}
        px={3}
      >
        <Box pb={3}>
          <img src={logoUrl} width={210} alt="BigOne Logo" />
        </Box>
        <Typography paragraph variant="h4" color={white}>
          {title}
        </Typography>
        <Typography gutterBottom variant="subtitle2" color={white}>
          {body}
        </Typography>

        <Container style={{ display: 'flex', justifyContent: 'center' }}>
          <Box p={4}>
            {backToBigOneButton && (
              <Button
                variant="contained"
                color="info"
                size="large"
                href={process.env.REACT_APP_URL_ONE_PAC_WEB}
                sx={{ m: 1 }}
              >
                {t('common.buttons.back_to_bigone')}
              </Button>
            )}
            {backToHomeButton && (
              <Button
                component={Link}
                variant="contained"
                color="info"
                size="large"
                to={routeHome}
                sx={{ m: 1 }}
              >
                {t('common.buttons.back_to_home')}
              </Button>
            )}
            {logoutButton && <LogoutButton />}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
