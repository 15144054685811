import { css } from '@emotion/react';
import { grayBackground } from 'const/colors';

export const formSectionRootStyle = css`
  min-height: 55px;
  padding: 16px 24px;
  line-height: 23px;
  background: ${grayBackground};

  font-family: 'ScandiaWebMedium';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  margin-bottom: 16px;
  margin-top: 16px;
`;
