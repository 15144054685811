/** @jsxRuntime classic */
/** @jsx jsx */
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';
import { FC } from 'react';
import { buttonRootStyle } from './Button.style';
import { jsx } from '@emotion/react';

export interface ButtonProps extends MuiButtonProps {}

const Button: FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <MuiButton {...props} css={buttonRootStyle}>
      {children}
    </MuiButton>
  );
};

export default Button;
