import { gray700, white } from 'const/colors';
import { css } from '@emotion/react';

export const tooltipRootStyle = css`
  cursor: help !important;
  pointer-events: initial !important;
`;

export const tooltipPopperStyle = css`
  .MuiTooltip-tooltip {
    text-align: center;

    font-family: 'ScandiaWebMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    color: ${white};
    background: ${gray700};

    max-width: none;
    margin: 2px !important;

    white-space: pre-line;
  }
`;
