export interface UseOrderWorkflowListParams {
  onOpenForm: (id?: string) => void;
}

export function useOrderWorkflowList({
  onOpenForm,
}: UseOrderWorkflowListParams) {
  function handleNewFlow() {
    onOpenForm();
  }

  function handleEditFlow(id: string) {
    onOpenForm(id);
  }

  return {
    handleNewFlow,
    handleEditFlow,
  };
}
