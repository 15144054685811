/** @jsxRuntime classic */
/** @jsx jsx */
import { IconButton, TableCell, TableRow } from '@mui/material';
import {
  UseOrderWorkflowListItemParams,
  useOrderWorkflowListItem,
} from './OrderWorkflowListItem.hook';
import {
  getConditioningOperatorName,
  getDocumentOptionName,
  getDocumentOrderTypeName,
} from '../utils';
import { getSupportCategoryName, joinListInSentence } from 'utils/mappers';
import ActivationBadge from 'components/ActivationBadge/ActivationBadge';
import AvatarStack from 'components/AvatarStack/AvatarStack';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FC } from 'react';
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';

export interface OrderWorkflowListItemProps
  extends UseOrderWorkflowListItemParams {
  handleOpenDialog: () => void;
  setItemToDelete: React.Dispatch<React.SetStateAction<string | null>>;
}

const OrderWorkflowListItem: FC<OrderWorkflowListItemProps> = ({
  it,
  onOpenEditMenu,
  handleOpenDialog,
  setItemToDelete,
}) => {
  const { t } = useTranslation();
  const { handleOpenMenu, condition, avatars } = useOrderWorkflowListItem({
    it,
    onOpenEditMenu,
  });

  return (
    <TableRow>
      <TableCell>{getSupportCategoryName(it.supportCategory, t)}</TableCell>
      <TableCell>
        {joinListInSentence(
          it.documentOptions.map((v) => getDocumentOptionName(v, t)),
          'or',
          t
        )}
      </TableCell>
      <TableCell>
        {joinListInSentence(
          it.documentOrderTypes.map((v) => getDocumentOrderTypeName(v, t)),
          'or',
          t
        )}
      </TableCell>
      <TableCell>
        {condition
          ? t('pages.order_workflow.list.condition_pricing')
          : t('pages.order_workflow.list.condition_none')}
      </TableCell>
      <TableCell>
        {condition &&
          t('pages.order_workflow.list.criterion_pricing', {
            operator: getConditioningOperatorName(condition.operator, t),
            value: condition.value,
          })}
      </TableCell>
      <TableCell>
        <AvatarStack avatars={avatars} />
      </TableCell>
      <TableCell>
        <ActivationBadge active={it.active} />
      </TableCell>
      <TableCell>
        <IconButton onClick={handleOpenMenu}>
          <EditOutlinedIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            setItemToDelete(it.id);
            handleOpenDialog();
          }}
        >
          <DeleteForeverOutlinedIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default OrderWorkflowListItem;
