/** @jsxRuntime classic */
/** @jsx jsx */
import { UseSwitchProps, useSwitch } from '@mui/base';
import {
  switchInputStyle,
  switchLabelActiveStyle,
  switchLabelStyle,
  switchRootStyle,
  switchThumbCheckedStyle,
  switchThumbStyle,
} from './ActivationSwitch.style';
import { forwardRef } from 'react';
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';

export interface ActivationSwitchProps extends UseSwitchProps {
  label: string;
  activeText?: string;
  inactiveText?: string;
  className?: string;
}

const ActivationSwitch = forwardRef<HTMLInputElement, ActivationSwitchProps>(
  ({ label, activeText, inactiveText, className, ...props }, ref) => {
    const { t } = useTranslation();
    const { getInputProps, checked } = useSwitch(props);

    return (
      <span css={switchRootStyle} className={className}>
        <span
          css={[switchThumbStyle, checked && switchThumbCheckedStyle]}
        ></span>
        <span css={[switchLabelStyle, checked && switchLabelActiveStyle]}>
          {activeText ?? t('common.buttons.switch_active')}
        </span>
        <span css={[switchLabelStyle, !checked && switchLabelActiveStyle]}>
          {inactiveText ?? t('common.buttons.switch_inactive')}
        </span>
        <input
          {...getInputProps(props)}
          ref={ref}
          css={switchInputStyle}
          aria-label={label}
        />
      </span>
    );
  }
);

export default ActivationSwitch;
