import { BigShopConfigCategory } from 'models/graphql/globalTypes';
import { TFunction } from 'react-i18next';

export function getSupportCategoryName(
  category: BigShopConfigCategory,
  t: TFunction
): string {
  switch (category) {
    case BigShopConfigCategory.AdvertisingPrints:
      return t('common.form.category_names.advertising_prints');
    case BigShopConfigCategory.AdvertisingItems:
      return t('common.form.category_names.advertising_items');
    case BigShopConfigCategory.Digital:
      return t('common.form.category_names.digital');
    case BigShopConfigCategory.SignagePos:
      return t('common.form.category_names.signage_pos');
    case BigShopConfigCategory.StationeryShop:
      return t('common.form.category_names.stationery');
    default:
      return category;
  }
}

export function getAutocompleteValue<T>(
  options: { label: string; value: T }[],
  label: string
): T {
  const res = options.find((v) => v.label === label);
  if (res === undefined) {
    throw new Error('Invalid option selected');
  }
  return res.value;
}

export function joinListInSentence(
  list: string[],
  type: 'and' | 'or' | 'both',
  t: TFunction
): string {
  if (list.length === 0) {
    return '';
  } else if (list.length === 1) {
    return list[0];
  }

  const body = list.slice(0, list.length - 1);
  const tail = list[list.length - 1];
  return `${body.join(', ')} ${t(`common.list.join_${type}`)} ${tail}`;
}
