import { Container } from '@mui/material';
import { Header } from 'components/Header/Header';
import { Outlet } from 'react-router-dom';
import React from 'react';

type Props = {};

export const GlobalLayout: React.FunctionComponent<Props> = () => {
  return (
    <div>
      <Header />
      <Container maxWidth="xl">
        <Outlet />
      </Container>
    </div>
  );
};
