import { blue500, gray500, grayBackground, red, white } from 'const/colors';
import { createTheme } from '@mui/material/styles';
// A custom theme for this app

export const theme = createTheme({
  palette: {
    primary: {
      main: gray500,
    },
    secondary: {
      main: blue500,
    },
    error: {
      main: red,
    },
    background: {
      default: white,
      paper: grayBackground,
    },
  },
  typography: {
    fontFamily: 'ScandiaWebRegular',
  },
});
