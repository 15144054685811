import { MouseEvent, useMemo } from 'react';
import { GetOrderWorkflowList_orderWorkflows } from 'models/graphql/GetOrderWorkflowList';

export interface UseOrderWorkflowListItemParams {
  it: GetOrderWorkflowList_orderWorkflows;
  onOpenEditMenu: (anchor: HTMLElement, id: string) => void;
}

export function useOrderWorkflowListItem({
  it,
  onOpenEditMenu,
}: UseOrderWorkflowListItemParams) {
  function handleOpenMenu(e: MouseEvent<HTMLElement>) {
    onOpenEditMenu(e.currentTarget, it.id);
  }

  const condition = useMemo(
    () =>
      it.priceConditioning && it.priceConditioning.length > 0
        ? it.priceConditioning[0]
        : undefined,
    [it]
  );

  const avatars = useMemo(
    () =>
      it.validators
        .flatMap((v) => [v.mainUser, ...v.fallbackUsers])
        .filter((v, i, a) => i === a.findIndex((x) => x.id === v.id)) // extract unique values
        .map((v) => ({
          id: v.id,
          name: `${v.givenName} ${v.familyName}`,
        })),
    [it]
  );

  return {
    handleOpenMenu,
    condition,
    avatars,
  };
}
