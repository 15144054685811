import { BaseSyntheticEvent } from 'react';
import Button from 'components/Button/Button';
import FormDialog from 'components/FormDialog/FormDialog';
import { useTranslation } from 'react-i18next';

export interface OrderWorkflowDeleteDialogProps {
  onClose: (e: BaseSyntheticEvent) => void;
  onSubmit: (e: BaseSyntheticEvent) => void;
  open: boolean;
}

const OrderWorkflowDeleteDialog = ({
  onClose,
  onSubmit,
  open,
}: OrderWorkflowDeleteDialogProps) => {
  const { t } = useTranslation();
  return (
    <FormDialog
      data-testid="dialog"
      onClose={onClose}
      onSubmit={onSubmit}
      open={open}
      renderTitle={() => t('delete_dialog.title')}
      renderRightActions={({ onClose, onSubmit }) => (
        <>
          <Button onClick={onClose}>{t('common.buttons.later')}</Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="secondary"
            data-testid="delete"
          >
            {t('common.buttons.delete_now')}
          </Button>
        </>
      )}
    >
      <h4>{t('delete_dialog.subtitle')} </h4>
      <p>{t('delete_dialog.message')}</p>
    </FormDialog>
  );
};

export default OrderWorkflowDeleteDialog;
