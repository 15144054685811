import { blue500, lightBlue500, white } from 'const/colors';
import { css } from '@emotion/react';

export const switchRootStyle = css`
  display: inline-block;
  height: 20px;
  width: 128px;
  border-radius: 10px;
  padding: 2px;
  position: relative;
  vertical-align: middle;
  z-index: 0;

  background: ${lightBlue500};
  color: ${blue500};

  text-align: center;
  text-transform: unset;
  font-family: ScandiaWebMedium;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

export const switchThumbStyle = css`
  position: absolute;
  display: inline-block;
  top: 2px;
  left: 2px;
  width: 62px;
  height: 16px;
  border-radius: 8px;
  z-index: -1;

  background: ${blue500};

  transition: transform 0.15s ease;
  transform: translateX(100%);
`;

export const switchThumbCheckedStyle = css`
  transform: translateX(0%);
`;

export const switchLabelStyle = css`
  display: inline-block;
  width: 62px;

  transition: color 0.15s ease;
`;

export const switchLabelActiveStyle = css`
  color: ${white};
`;

export const switchInputStyle = css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`;
