import 'i18n/i18n';

import { CssBaseline, ThemeProvider } from '@mui/material';

import { AppRoutes } from 'routes/AppRoutes';
import { Auth0Provider } from 'globalContexts/Auth0Context';
import { AuthenticateApolloProvider } from 'utils/apollo/AuthenticateApolloProvider';
import { BrowserRouter } from 'react-router-dom';
import { Fonts } from 'styles/theme/fonts';
import React from 'react';
import { UserProvider } from 'globalContexts/UserContext';
import { theme } from 'styles/theme/theme';

export const App = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Fonts />
        <Auth0Provider>
          <AuthenticateApolloProvider>
            <UserProvider>
              <AppRoutes />
            </UserProvider>
          </AuthenticateApolloProvider>
        </Auth0Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
};
