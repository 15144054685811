/** @jsxRuntime classic */
/** @jsx jsx */
import { FC, ReactNode } from 'react';
import { formSectionRootStyle } from './FormSection.style';
import { jsx } from '@emotion/react';

export interface FormSectionProps {
  children: ReactNode;
}

export const FormSection: FC<FormSectionProps> = ({ children }) => {
  return <div css={formSectionRootStyle}>{children}</div>;
};

export default FormSection;
