/** @jsxRuntime classic */
/** @jsx jsx */
import { Menu as MuiMenu, MenuProps as MuiMenuProps } from '@mui/material';
import { FC } from 'react';
import { jsx } from '@emotion/react';
import { menuRootStyle } from './Menu.style';

export interface MenuProps extends MuiMenuProps {}

const Menu: FC<MenuProps> = ({ children, ...props }) => {
  return (
    <MuiMenu {...props} css={menuRootStyle}>
      {children}
    </MuiMenu>
  );
};

export default Menu;
