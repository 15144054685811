import { Box, CircularProgress, Typography } from '@mui/material';

import React from 'react';

export interface LoaderProps {
  height?: number | string;
  error?: any;
  paperWrap?: boolean;
}

export const Loader = ({ height, error }: LoaderProps): JSX.Element => {
  return (
    <Box
      display="flex"
      height={height || 800}
      flexDirection="column"
      textAlign="center"
      justifyContent="center"
      alignItems="center"
    >
      {error ? (
        <div>
          <Box px={3} mt={3}>
            <Typography variant="subtitle2">Error</Typography>
            <Typography>
              Alert
              <br />
              Refresh
            </Typography>
          </Box>
        </div>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};
