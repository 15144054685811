/** @jsxRuntime classic */
/** @jsx jsx */
import {
  TableHead as MuiTableHead,
  TableHeadProps as MuiTableHeadProps,
} from '@mui/material';
import { FC } from 'react';
import { jsx } from '@emotion/react';
import { tableHeadRootStyle } from './TableHead.style';

export interface TableHeadProps extends MuiTableHeadProps {}

const TableHead: FC<TableHeadProps> = ({ children, ...props }) => (
  <MuiTableHead {...props} css={tableHeadRootStyle}>
    {children}
  </MuiTableHead>
);

export default TableHead;
