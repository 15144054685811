import {
  DeleteOrderWorkflow,
  DeleteOrderWorkflowVariables,
} from 'models/graphql/DeleteOrderWorkflow';
import { DELETE_ORDER_WORKFLOW } from 'graphql/orderWorkflow';
import { useMutation } from '@apollo/client';
import { useOrderWorkflowPage } from '../OrderWorkflowPage.hook';
import { useState } from 'react';

export const useOrderWorkflowDeleteDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const [itemToDelete, setItemToDelete] = useState<string | null>(null);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDilalog = () => setIsDialogOpen(false);

  const handleItemToDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    setItemToDelete(id);
  };
  const { listRefetch } = useOrderWorkflowPage();
  const [deleteOrderWorkflow] = useMutation<
    DeleteOrderWorkflow,
    DeleteOrderWorkflowVariables
  >(DELETE_ORDER_WORKFLOW);

  const deleteItem = async (
    event: React.BaseSyntheticEvent<object, any, any>,
    id: string | null
  ) => {
    if (id) {
      const { data } = await deleteOrderWorkflow({ variables: { id } });
      if (!data) {
        return;
      }
      if (data?.deleteOrderWorkflow.acknowledged) {
        listRefetch();
      }
    }
  };

  const onSubmit = (event: React.BaseSyntheticEvent<object, any, any>) => {
    deleteItem(event, itemToDelete);
    handleCloseDilalog();
  };

  return {
    isDialogOpen,
    handleOpenDialog,
    handleCloseDilalog,
    handleItemToDelete,
    itemToDelete,
    onSubmit,
    setItemToDelete,
  };
};
