import { blue500 } from 'const/colors';
import { css } from '@emotion/react';

export const buttonRootStyle = css`
  border-radius: 200px;
  text-transform: unset;
  font-family: ScandiaWebMedium;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  height: 40px;
  padding-left: 24px;
  padding-right: 24px;

  box-shadow: none !important;

  &.MuiButton-text {
    color: ${blue500};
    padding-left: 16px;
    padding-right: 16px;
  }
`;
