import { gray200, white } from 'const/colors';
import { css } from '@emotion/react';

export const dialogRootStyle = css`
  .MuiDialog-paper {
    max-width: 800px;
    width: 800px;
    background: ${white};
  }
`;

export const dialogTitleStyle = css`
  border-bottom: 2px solid ${gray200};

  text-transform: unset;
  font-family: ScandiaWebBold;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
`;

export const dialogCloseStyle = css`
  float: right;
`;

export const dialogActionsStyle = css`
  border-top: 2px solid ${gray200};
  padding: 16px 24px;
`;

export const dialogContentStyle = css`
  padding-top: 16px !important;
`;
