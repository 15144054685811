/** @jsxRuntime classic */
/** @jsx jsx */
import Avatar, { AvatarProps } from 'components/Avatar/Avatar';
import {
  avatarStackItemStyle,
  avatarStackRootStyle,
} from './AvatarStack.style';
import { FC } from 'react';
import { jsx } from '@emotion/react';

export interface AvatarStackProps {
  avatars: (Pick<AvatarProps, 'name' | 'image'> & { id: string })[];
}

const AvatarStack: FC<AvatarStackProps> = ({ avatars }) => (
  <div css={avatarStackRootStyle}>
    {avatars.map((it) => (
      <Avatar
        key={it.id}
        name={it.name}
        image={it.image}
        tooltip
        css={avatarStackItemStyle}
      />
    ))}
  </div>
);

export default AvatarStack;
