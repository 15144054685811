import { gray300, gray500, white } from 'const/colors';
import { css } from '@emotion/react';

export const headerStyle = css`
  .toolbar {
    flex-wrap: nowrap;
    background-color: ${white};
    box-shadow: 0px 1px 1px ${gray300};
    border: none;
    .logo-box {
      flex-grow: 1;
      .logo-link {
        justify-content: space-between;
        width: 200px;
        display: flex;
        color: inherit;
        text-decoration: none;
      }
    }
    nav {
      .button-nav {
        background-color: ${white};
        p {
          font-family: ScandiaWebMedium;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 26px;
          letter-spacing: -0.03em;
          text-transform: none;
        }
      }
      .button-nav:hover {
        background-color: ${white};
      }
    }
    .logout-box {
      color: ${gray500};
      display: flex;
      justify-content: space-between;

      hr {
        margin-right: 8px;
        height: 24px;
        margin-top: 8px;
      }

      button {
        margin-right: -8px;
      }
    }
  }
`;
