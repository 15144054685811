/** @jsxRuntime classic */
/** @jsx jsx */
import { FC } from 'react';
import { Avatar as MuiAvatar } from '@mui/material';
import Tooltip from 'components/Tooltip/Tooltip';
import { avatarRootStyle } from './Avatar.style';
import { jsx } from '@emotion/react';

export interface AvatarProps {
  name: string;
  image?: string;
  tooltip?: boolean;
  className?: string;
}

// https://mui.com/material-ui/react-avatar/#BackgroundLetterAvatars.tsx
function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const Avatar: FC<AvatarProps> = ({
  name,
  image,
  tooltip = false,
  className,
}) => {
  const avatar = (
    <MuiAvatar
      sx={{ bgcolor: stringToColor(name) }}
      alt={name}
      src={image}
      className={className}
      css={avatarRootStyle}
    >
      {name.substring(0, 1)}
    </MuiAvatar>
  );

  if (tooltip) {
    return <Tooltip title={name}>{avatar}</Tooltip>;
  } else {
    return avatar;
  }
};

export default Avatar;
