import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';

let client: ApolloClient<NormalizedCacheObject>;

export const getClientInstance = (
  accessToken: string
): ApolloClient<NormalizedCacheObject> => {
  if (client) {
    return client;
  }
  return (client = new ApolloClient({
    uri: process.env.REACT_APP_API_ENDPOINT,
    cache: new InMemoryCache(),
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }));
};
