/** @jsxRuntime classic */
/** @jsx jsx */
import {
  activationBadgeActiveStyle,
  activationBadgeInactiveStyle,
  activationBadgeRootStyle,
} from './ActivationBadge.style';
import { FC } from 'react';
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';

export interface ActivationBadgeProps {
  active: boolean;
  activeText?: string;
  inactiveText?: string;
}

const ActivationBadge: FC<ActivationBadgeProps> = ({
  active,
  activeText,
  inactiveText,
}) => {
  const { t } = useTranslation();
  return (
    <span
      css={[
        activationBadgeRootStyle,
        active && activationBadgeActiveStyle,
        !active && activationBadgeInactiveStyle,
      ]}
    >
      {active
        ? activeText ?? t('common.buttons.switch_active')
        : inactiveText ?? t('common.buttons.switch_inactive')}
    </span>
  );
};

export default ActivationBadge;
