/** @jsxRuntime classic */
/** @jsx jsx */
import { BaseSyntheticEvent, FC, ReactNode } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import {
  dialogActionsStyle,
  dialogCloseStyle,
  dialogContentStyle,
  dialogRootStyle,
  dialogTitleStyle,
} from './FormDialog.style';
import CloseIcon from '@mui/icons-material/Close';
import { jsx } from '@emotion/react';

interface FormDialogRenderProps {
  onClose: FormDialogProps['onClose'];
  onSubmit: FormDialogProps['onSubmit'];
}

export interface FormDialogProps extends DialogProps {
  renderTitle?: (props: FormDialogRenderProps) => ReactNode;
  onClose: (e: BaseSyntheticEvent) => void;
  onSubmit: (e: BaseSyntheticEvent) => void;
  renderRightActions?: (props: FormDialogRenderProps) => ReactNode;
  renderLeftActions?: (props: FormDialogRenderProps) => ReactNode;
}

const FormDialog: FC<FormDialogProps> = ({
  renderTitle,
  onClose,
  children,
  onSubmit,
  renderRightActions,
  renderLeftActions,
  ...props
}) => {
  return (
    <Dialog {...props} onClose={onClose} css={dialogRootStyle}>
      <form onSubmit={onSubmit}>
        <DialogTitle css={dialogTitleStyle}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            css={dialogCloseStyle}
          >
            <CloseIcon />
          </IconButton>
          {renderTitle?.({ onClose, onSubmit })}
        </DialogTitle>
        <DialogContent css={dialogContentStyle}>{children}</DialogContent>
        <DialogActions css={dialogActionsStyle}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={true}>
              {renderLeftActions?.({ onClose, onSubmit })}
            </Grid>
            <Grid item xs={12} sm="auto" justifyContent="flex-end">
              {renderRightActions?.({ onClose, onSubmit })}
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormDialog;
