import { css } from '@emotion/react';

export const avatarStackRootStyle = css`
  display: flex;
  flex-direction: row;
`;

export const avatarStackItemStyle = css`
  margin: 0 -12px 0 0;
`;
