/** @jsxRuntime classic */
/** @jsx jsx */
import {
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
} from '@mui/material';
import { FC } from 'react';
import { jsx } from '@emotion/react';
import { menuItemRootStyle } from './MenuItem.style';

export interface MenuItemProps extends MuiMenuItemProps {}

const MenuItem: FC<MenuItemProps> = ({ children, ...props }) => {
  return (
    <MuiMenuItem {...props} css={menuItemRootStyle}>
      {children}
    </MuiMenuItem>
  );
};

export default MenuItem;
