import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { getClientInstance } from './client';
import { useAuth0 } from 'globalContexts/Auth0Context';

export const AuthenticateApolloProvider: React.FunctionComponent = ({
  children,
}) => {
  const { accessToken } = useAuth0();
  return (
    <ApolloProvider client={getClientInstance(accessToken!)}>
      {children}
    </ApolloProvider>
  );
};
