import { DocumentOptions, DocumentOrderType } from 'models/graphql/globalTypes';
import {
  UseOrderWorkflowFormParams,
  useOrderWorkflowForm,
} from './OrderWorkflowForm.hook';
import ActivationSwitch from 'components/ActivationSwitch/ActivationSwitch';
import Button from 'components/Button/Button';
import { FC } from 'react';
import FormAutocomplete from 'components/FormAutocomplete/FormAutocomplete';
import FormCheckbox from 'components/FormCheckbox/FormCheckbox';
import FormDialog from 'components/FormDialog/FormDialog';
import FormGroup from 'components/FormGroup/FormGroup';
import FormLabel from 'components/FormLabel/FormLabel';
import FormSection from 'components/FormSection/FormSection';
import FormSelect from 'components/FormSelect/FormSelect';
import FormTextField from 'components/FormTextField/FormTextField';
import { Grid } from '@mui/material';
import { getSupportCategoryName } from 'utils/mappers';
import { useTranslation } from 'react-i18next';

export interface OrderWorkflowFormProps extends UseOrderWorkflowFormParams {
  open: boolean;
}

const OrderWorkflowForm: FC<OrderWorkflowFormProps> = ({
  onClose,
  open,
  disableCategories,
  edit,
}) => {
  const { t } = useTranslation();
  const {
    handleClose,
    handleSubmit,
    form: {
      register,
      control,
      formState: { errors, isSubmitting },
    },
    categoryOptions,
    recipientOptions,
    conditionAdded,
    defaultValues,
    criterionOptions,
    hasRecipient,
    relayOptions,
  } = useOrderWorkflowForm({ onClose, disableCategories, edit });

  return (
    <FormDialog
      onClose={handleClose}
      open={open}
      onSubmit={handleSubmit}
      renderTitle={() =>
        edit
          ? t('pages.order_workflow.form.title_edit', {
              category: getSupportCategoryName(edit.supportCategory, t),
            })
          : t('pages.order_workflow.form.title_add')
      }
      renderLeftActions={() => (
        <>
          <ActivationSwitch
            {...register('active')}
            defaultChecked={defaultValues.active}
            label={t('common.buttons.switch_status')}
          />
        </>
      )}
      renderRightActions={({ onClose, onSubmit }) => (
        <>
          <Button onClick={onClose} variant="text">
            {t('common.buttons.cancel')}
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="secondary"
            disabled={isSubmitting}
          >
            {t('common.buttons.save')}
          </Button>
        </>
      )}
    >
      {!edit && (
        <FormGroup>
          <FormLabel
            htmlFor="order-workflow-category"
            error={!!errors.category}
          >
            {t('pages.order_workflow.form.field_category')}
          </FormLabel>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormSelect
                {...register('category', { required: true })}
                error={!!errors.category}
                id="order-workflow-category"
                defaultValue={defaultValues.category}
                options={categoryOptions}
                disabledHelpText={t(
                  'pages.order_workflow.form.field_category_value_disabled_help'
                )}
                data-testid="order-workflow-category"
              />
            </Grid>
          </Grid>
        </FormGroup>
      )}
      <FormSection>{t('pages.order_workflow.form.section_params')}</FormSection>
      <FormGroup>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormLabel
              helpText={t('pages.order_workflow.form.field_type_help')}
              error={!!errors.type}
            >
              {t('pages.order_workflow.form.field_type')}
            </FormLabel>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormCheckbox
              {...register('type', { required: true })}
              value={DocumentOrderType.FreeOrder}
              defaultChecked={defaultValues.type?.includes(
                DocumentOrderType.FreeOrder
              )}
              label={t('pages.order_workflow.form.field_type_value_order')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormCheckbox
              {...register('type', { required: true })}
              value={DocumentOrderType.Commitment}
              defaultChecked={defaultValues.type?.includes(
                DocumentOrderType.Commitment
              )}
              label={t('pages.order_workflow.form.field_type_value_commitment')}
            />
          </Grid>
        </Grid>
      </FormGroup>
      <FormGroup>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormLabel
              helpText={t('pages.order_workflow.form.field_option_help')}
              error={!!errors.option}
            >
              {t('pages.order_workflow.form.field_option')}
            </FormLabel>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormCheckbox
              {...register('option', { required: true })}
              value={DocumentOptions.customizable}
              defaultChecked={defaultValues.option?.includes(
                DocumentOptions.customizable
              )}
              label={t(
                'pages.order_workflow.form.field_option_value_customizable'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormCheckbox
              {...register('option', { required: true })}
              value={DocumentOptions.notCustomizable}
              defaultChecked={defaultValues.option?.includes(
                DocumentOptions.notCustomizable
              )}
              label={t(
                'pages.order_workflow.form.field_option_value_noncustomizable'
              )}
            />
          </Grid>
        </Grid>
      </FormGroup>
      <FormGroup>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={8}>
            <FormLabel error={!!errors.add_condition}>
              {t('pages.order_workflow.form.field_add_condition')}
            </FormLabel>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormCheckbox
              {...register('add_condition')}
              asSwitch
              defaultChecked={defaultValues.add_condition}
              label={
                conditionAdded
                  ? t('common.buttons.switch_yes')
                  : t('common.buttons.switch_no')
              }
              data-testid="order-workflow-add-condition"
            />
          </Grid>
        </Grid>
      </FormGroup>
      {conditionAdded && (
        <FormGroup>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormLabel
                htmlFor="order-workflow-criterion"
                error={!!errors.criterion}
              >
                {t('pages.order_workflow.form.field_criterion')}
              </FormLabel>
              <FormSelect
                {...register('criterion', { required: conditionAdded })}
                error={!!errors.criterion}
                id="order-workflow-criterion"
                defaultValue={defaultValues.criterion}
                options={criterionOptions}
                data-testid="order-workflow-criterion"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormLabel htmlFor="order-workflow-value" error={!!errors.value}>
                {t('pages.order_workflow.form.field_value')}
              </FormLabel>
              <FormTextField
                {...register('value', {
                  required: conditionAdded,
                  valueAsNumber: true,
                })}
                error={!!errors.value}
                id="order-workflow-value"
                type="number"
                endAdornment="€"
                defaultValue={defaultValues.value}
                data-testid="order-workflow-value"
              />
            </Grid>
          </Grid>
        </FormGroup>
      )}
      <FormSection>
        {t('pages.order_workflow.form.section_recipients')}
      </FormSection>
      <FormGroup>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormLabel
              htmlFor="order-workflow-recipient"
              error={!!errors.recipient}
            >
              {t('pages.order_workflow.form.field_recipient')}
            </FormLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormAutocomplete
              control={control}
              name="recipient"
              rules={{ required: true }}
              id="order-workflow-recipient"
              options={recipientOptions.map((v) => v.label).concat([''])}
              error={!!errors.recipient}
            />
          </Grid>
        </Grid>
      </FormGroup>
      <FormGroup>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormLabel
              htmlFor="order-workflow-relay"
              helpText={t('pages.order_workflow.form.field_relay_help')}
              required={false}
              error={!!errors.relay}
            >
              {t('pages.order_workflow.form.field_relay')}
            </FormLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormAutocomplete
              control={control}
              name="relay"
              id="order-workflow-relay"
              data-testid="order-workflow-relay"
              options={relayOptions.map((v) => v.label).concat([''])}
              placeholder={t('common.form.optional')}
              disabled={!hasRecipient}
            />
          </Grid>
        </Grid>
      </FormGroup>
    </FormDialog>
  );
};

export default OrderWorkflowForm;
